<template>
  <div class="form-group">
    <VueCtkDateTimePicker
      v-model="inputVal"
      dark
      range
      :label="fieldLabel"
      :color="crcaColors.primary"
      :max-date="maxDate"
      format="YYYY-MM-DD 00:00:00"
      formatted="l"
      noClearButton
      :auto-close="true"
      :id="id"
      v-bind="$attrs"
      :custom-shortcuts="presets"
    />
    <font-awesome-icon class="date-picker-icon" icon="calendar-alt" />
    <i class="bar"></i>
  </div>
</template>

<script>
  import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'; // https://github.com/chronotruck/vue-ctk-date-time-picker
  import moment from 'moment';
  import { defaultDateRange, dateRanges, todayFormatted } from '@/data';

  /**
   * @name DateRange
   * Component to set our app defaults for our range picker which uses the `vue-ctk-date-time-picker` package.
   *
   * To find out more about how to use this imported component, please vue the documentation for it on
   * [GitHub](https://github.com/chronotruck/vue-ctk-date-time-picker). All attrs associated with this plugin are
   * passed through this local implimentation via `v-bind`. So, beyond the properties outlined in this document,
   * you may use those outlined for the `vue-ctk-date-time-picker` package as well.
   *
   * ## To Use
   * Add to template. Pass in props as needed to configure. The range value has a default set in
   * `src/data/dateRanges.js`.
   *
   * ```html
   * <date-range v-modal="range" />
   * ```
   *
   * ```javascript
   * import dateRange from './../../base/forms/date_range';
   *
   * export default {
   *   component: {
   *    dateRange
   *    },
   *   data() {
   *      return {
   *        range: {
   *          start:'2020-11-16',
   *          end :'2020-11-22'
   *        }
   *      }
   *   }
   * }
   * ```
   * Enjoy!
   */
  export default {
    name: 'DateRange',
    components: {
      VueCtkDateTimePicker
    },
    props: {
      /**
       * Date Range value object. Requires a prop for `start` and one of `end`. Both must be a valid date.
       *
       * Example:
       * ```
       * {
       *   start:'2020-11-16',
       *   end :'2020-11-22'
       *  }
       * ```
       */
      value: {
        type: Object,
        default() {
          return defaultDateRange;
        }
      },
      /**
       * Set a custom field display label for the picker input.
       */
      fieldLabel: {
        type: String,
        require: true,
        default() {
          return 'Date Range';
        }
      },
      /**
       * Set a custom HTML id for the componet as whole. Will be used to prifix ids within the component.
       */
      id: {
        type: String,
        require: true,
        default() {
          return 'dateRange' + Math.floor(Math.random() * 1000 + 1);
        }
      }
    },
    computed: {
      /**
       * Value to bind and pass through the reactive value into the `vue-ctk-date-time-picker` component.
       */
      inputVal: {
        get() {
          if (this.value) return this.value;
          else return defaultDateRange;
        },
        set(val) {
          /**
           * @arg {Object} val - The Date Range Object
           */
          this.$emit('input', val);
        }
      },
      presets() {
        let rangesToUse = [
          dateRanges.lastQuarterFromOMA,
          dateRanges.twoQuarterFromOMA,
          dateRanges.threeQuarterFromOMA,
          dateRanges.fourQuarterFromOMA,
          dateRanges.lastYearFromOMA,
          dateRanges.twoYearsFromOMA,
          dateRanges.threeYearsFromOMA
        ];
        let pickerPresets = [];

        for (let i in rangesToUse) {
          if (rangesToUse[i].year >= 2019) {
            pickerPresets.push({
              key: rangesToUse[i].shortcut,
              label: rangesToUse[i].label,
              value: () => {
                return {
                  start: moment(rangesToUse[i].start),
                  end: moment(rangesToUse[i].end)
                };
              }
            });
          }
        }

        return pickerPresets;
      },
      maxDate() {
        return todayFormatted;
      }
    }
  };
</script>
